import React, { useEffect, useState } from 'react';
import { Layout } from '../components/Layout';
import Search from '../components/Search';
import Image from '../components/Image';
import Mastodon from './Mastodon';
import ReactPaginate from 'react-paginate';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';

let itemCount = 15;

export function PaginatedItems({ itemsPerPage, items }) {
    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {
        itemCount = itemsPerPage;
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <Layout>
                { typeof window !== "undefined" ? !["photos", "pens", "blog", "videos"].includes(window.location.href.split("/")[3].replace("/", "")) && <Mastodon /> : ""}
                <div className='card-width'>
                    <Search
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                    />
                </div>
                <div className='cards'>
                    {
                        searchTerm === "" ?
                            currentItems.map(
                                ({ id, frontmatter, fields }) => (
                                    <div key={id} className='story'>
                                        <a href={fields.slug}>
                                            <Image
                                                src={frontmatter.cover.publicURL}
                                                imageTitle={frontmatter.title}
                                            />
                                        </a>
                                    </div>
                                )
                            )
                            :
                            items.filter(node => node.frontmatter.title.toLowerCase().includes(searchTerm.toLowerCase())).map(
                                ({ id, frontmatter, fields }) => (
                                    <div key={id} className='story'>
                                        <a href={fields.slug}>
                                            {!!frontmatter.cover ? (
                                                <img
                                                    className='story-img'
                                                    src={frontmatter.cover.publicURL}
                                                    alt={frontmatter.cover.title}
                                                />
                                            ) : null}
                                            <h1 className='story-title'>{frontmatter.title}</h1>
                                        </a>
                                    </div>
                                )
                            )
                    }
                </div>
            </Layout>
            <center>
                <div className='text-center' style={{ "textAlign": "center" }}>
                    {searchTerm === "" && pageCount > 1 ?
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            pageCount={pageCount}
                            previousLabel="<"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        /> : <></>}
                </div>
            </center>
        </>
    );
}

