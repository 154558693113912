import React, { useEffect, useState } from "react";

const Search = ({ searchTerm, setSearchTerm }) => {

    const [placeHolder, setPlaceHolder] = useState("search")

    useEffect(() => {
        console.log(window.location.href)
        setPlaceHolder(
            window.location.href.indexOf("/") ? `search ${window.location.href.split("/")[3].replace("/", "")}` : "search"
        )
    }, [])

    return (
        <div className="search cards-width centered" style={{marginBottom: "2px"}}>
            <input
                value={searchTerm}
                type="text"
                placeholder={placeHolder}
                id="search"
                className="search"
                onInput={(e) => setSearchTerm(e.target.value)}
                style={{borderRadius: "0px"}}
            />
        </div>
    )
}

export default Search;