import React, { useEffect, useState } from "react";

const Image = ({ src, imageTitle }) => {
    const [title, setTitle] = useState("image unavailable")

    useEffect(() => {
        setTitle(imageTitle);
    }, [])

    return (
        <><img
            className='story-img'
            src={src}
            alt={title}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "blinking-eyes-huh.gif";
            }} />
            <h1 className='story-title'>{title}</h1>
        </>
    )
};

export default Image;