import axios from 'axios';
import React, { useEffect, useState } from 'react';
const Mastodon = () => {
    const [status, setStatus] = useState("")
    const [userImage, setUserImage] = useState("");
    const [attachments, setAttachments] = useState([])
    const [link, setLink] = useState("")
    const targetStatus = 0

    useEffect(() => {
        axios.get("https://fosstodon.org/api/v1/accounts/109297552415524349/statuses")
            .then(response => {
                const updatedStatus = (response.data[targetStatus].reblog !== null)
                    ? response.data[targetStatus].reblog.content.replace(/<\/?[^>]+(>|$)/g, "")
                    : response.data[targetStatus].content.replace(/<\/?[^>]+(>|$)/g, "")
                setStatus(updatedStatus)
                sessionStorage.setItem("status", updatedStatus)
                const updatedUserImage = (response.data[targetStatus].reblog !== null)
                    ? response.data[targetStatus].reblog.account.avatar
                    : response.data[targetStatus].account.avatar
                setUserImage(updatedUserImage)
                sessionStorage.setItem("avatar", updatedUserImage)
                sessionStorage.setItem("date", new Date(Date.now()).toString)
                if (!response.data[targetStatus].media_attachments.length == 0) {
                    setAttachments(response.data[targetStatus].media_attachments)
                }
                setLink(response.data[targetStatus].account.url)
            })

    }, []);

    return (
        <a href={link} target="_blank" rel="noreferrer">
            <div className="cards-width mastodon" style={{ marginBottom: "-2px", display: "flex", flexDirection: "row" }}>
                <img src={userImage} className="userImage" alt="avatar" />
                <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "left" }}>
                    {status}
                    {attachments.length > 0 && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                justifyContent: "space-evenly",
                            }}
                        >
                            {attachments.map((attachment, index) => (
                                <img key={index} className="attachment" src={attachment.url} alt={`attachment-${index}`} />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </a>
    )
};

export default Mastodon;